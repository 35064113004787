export default {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Well in Time', // Navigation and Site Title
  siteTitleAlt: 'ArvinHuang Blog', // Alternative Site title for SEO
  siteUrl: 'https://blog.arvinh.info', // Domain of your site. No trailing slash!
  siteLanguage: 'zh-TW', // Language Tag on <html> element
  siteBanner: '/assets/banner.jpg', // Your image for og:image tag. You can find it in the /static folder
  defaultBg: '/assets/bg.png', // default post background header
  favicon: 'src/favicon.png', // Your image for favicons.
  siteDescription: 'Blog by Arvin Huang', // Your site description
  author: 'Arvin Huang', // Author for schemaORGJSONLD
  siteLogo: '/assets/the-flash.png', // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@ArvinHv', // Twitter Username - Optional
  ogSiteName: 'shouhan.huang', // Facebook Site Name - Optional
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: '#3498DB',
  backgroundColor: '#2b2e3c',

  // Settings for typography.ts
  headerFontFamily: 'huninn', // 'Bitter',
  bodyFontFamily: 'huninn, Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace, Open Sans',
  baseFontSize: '18px',

  // Social media
  siteFBAppID: '',

  //
  Google_Tag_Manager_ID: 'GTM-PHF66B5',
  POST_PER_PAGE: 4,
};
