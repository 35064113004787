import { FaFileAlt, FaTrophy, FaFolderOpen, FaGraduationCap } from 'react-icons/fa';
import { IconType } from 'react-icons';
interface IconInterface {
  [key: string]: IconType;
}
const iconSet: IconInterface = {
  fileAlt: FaFileAlt,
  trophy: FaTrophy,
  folderOpen: FaFolderOpen,
  graduaction: FaGraduationCap,
};

export default iconSet;
