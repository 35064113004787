import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Content } from '../components';
import iconSet from './Icons';
import ResumeContent from '../models/ResumeContent';

export const ResumeBlock = ({ logo, title, content }: ResumeContent) => {
  const Icon = iconSet[logo];
  return (
    <Content style={{ marginTop: '2rem' }} key={title}>
      <Icon size="1.5rem" style={{ verticalAlign: 'text-bottom' }} />
      <BlockTitle>{title}</BlockTitle>
      <SectionHr />
      {content.map(item => (
        <Fragment key={item.title}>
          <ExperienceDate>{item.date}</ExperienceDate>
          <ExperienceTitle>{item.title}</ExperienceTitle>
          <ExperienceDetailUl>
            {item.details.map((detail, idx) => (
              <ExperienceDetailLi key={`exp-detail-${idx}`}>{detail}</ExperienceDetailLi>
            ))}
            {item.link && (
              <ExperienceDetailLi>
                Link: <ExperienceLink href={item.link.href}>{item.link.title}</ExperienceLink>
              </ExperienceDetailLi>
            )}
          </ExperienceDetailUl>
          {item.projectScreenShot && (
            <ExperienceDetails>
              <summary>{'Screenshots'}</summary>
              {item.projectScreenShot.map((screenshot, idx) => (
                <ProjectScreenShotWrapper key={`screenshot-${idx}`}>
                  {screenshot.title && <ProjectScreenShotTitle>{screenshot.title}</ProjectScreenShotTitle>}
                  <img src={screenshot.href} />
                </ProjectScreenShotWrapper>
              ))}
            </ExperienceDetails>
          )}
        </Fragment>
      ))}
    </Content>
  );
};

const BlockTitle = styled.div`
  display: inline-block;
  margin-left: 5px;
`;

const SectionHr = styled.hr`
  margin-top: 10px;
`;

const ExperienceDate = styled.div`
  color: darkgray;
`;

const ExperienceTitle = styled.div`
  color: goldenrod;
`;

const ExperienceDetailUl = styled.ul`
  margin-top: 5px;
  margin-bottom: 0px;
`;

const ExperienceDetailLi = styled.li``;

const ExperienceLink = styled.a`
  color: #5ebcf3;
`;

const ExperienceDetails = styled.details`
  font-weight: bold;
  cursor: pointer;
  margin: 15px;
  margin-left: 0.6rem;
  summary {
    color: #4caf50;
    padding-bottom: 20px;
  }
  border-bottom: 1px dashed;
`;

const ProjectScreenShotWrapper = styled.div`
  text-align: center;
  cursor: initial;
`;

const ProjectScreenShotTitle = styled.div``;
