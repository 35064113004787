import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/media';
import { Button } from './Button';

const ScrollToTopBtn = styled(Button)`
  @media ${media.tablet} {
    opacity: 0.5;
    transform: scale(0.8);
    bottom: 0px;
  }
  @media ${media.phone} {
    opacity: 0.5;
    transform: scale(0.7);
    bottom: 0px;
  }
  position: fixed;
  right: 0;
  bottom: 60px;
  z-index: 2;
  img {
    margin: 0;
    height: 38px;
  }
`;

export const ScrollToTop = () => {
  return <ScrollToTopBtn>Top</ScrollToTopBtn>;
};
