import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/media';
import { Button } from './Button';

const BMCBtn = styled(Button)`
  @media ${media.tablet} {
    opacity: 0.5;
    transform: scale(0.8);
    bottom: 0px;
  }
  @media ${media.phone} {
    opacity: 0.5;
    transform: scale(0.7);
    bottom: 0px;
  }
  position: fixed;
  right: 0;
  bottom: 10px;
  z-index: 2;
  img {
    margin: 0;
    height: 38px;
  }
`;

export const BMCButton = () => {
  return (
    <BMCBtn as="a" target="_blank" href="https://www.buymeacoffee.com/arvinhv" data-hint="Buy me a coffee">
      <img src="/assets/coffee.svg" alt="Buy me a coffee" />
    </BMCBtn>
  );
};
